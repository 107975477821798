import { Http } from '@capacitor-community/http';

export const fetchRealtime = (success: Function, error: Function) => {
  // TODO: switch
  Http.get({ url: 'https://maribor-transit-backend.ihumhann.workers.dev/realtime' })
    // Http.get({ url: 'http://localhost:3000/realtime' })
    .then((data) => {
      console.log(data.status);
      console.log(data.data); // data received by server
      console.log(data.headers);

      success(data.data);
    })
    .catch((error) => {
      console.log(error.status);
      console.log(error.error); // error message as string
      console.log(error.headers);

      error(error.error);
    });
};
